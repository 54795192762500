.popup-messages-container {
  position: relative;
}
.popup-message-container {
  display: flex;
  margin-bottom: 25px;
  align-items: center;
  justify-content: flex-start;
  column-gap: 45px;
}
.popup-message-container.fade-in {
  opacity: 0;
  animation: fadeInAnimation 0.8s ease forwards;
}
.popup-message-container.right-aligned {
  justify-content: flex-end;
}
@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popup-message-text-wrapper {
  order: 2;
  width: 600px;
  position: relative;
}
.popup-message-text-wrapper.small {
  width: 480px;
}
.popup-message-text-wrapper.without-avatar {
  margin-left: 175px;
}
.bailey-message-container .popup-message-text-wrapper {
  margin-right: 175px;
}
.right-aligned .popup-message-text-wrapper {
  order: 1;
  margin-left: 175px;
}
.popup-message-text {
  min-height: 67.2px;
  padding: 15px 50px;
  border-radius: 8px;
  background: #fff;
  box-shadow: -8px 8px 0px 0px #D6BAF0;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
.bailey-message-container .popup-message-text {
  box-shadow: -8px 8px 0px 0px #A98DF6;
}
.benny-message-container .popup-message-text {
  box-shadow: 8px 8px 0px 0px #52C5CD;
}
.emma-message-container .popup-message-text {
  box-shadow: -8px 8px 0px 0px #F9C151;
}
.popup-message-text p {
  margin: 0;
}
.popup-message-text a {
  color: #5C91F5;
  font-weight: 700;
  text-decoration: none;
}

.popup-message-name {
  padding: 0 27.5px;
  border-radius: 4px;
  color: #fff;
  font-family: 'Mukta Vaani';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  top: -12px;
  background: #D6BAF0;
  left: 16px;
}
.bailey-message-container .popup-message-name {
  background: #8C64FB;
}
.benny-message-container .popup-message-name {
  background: #00BECB;
}
.right-aligned .popup-message-name {
  right: 16px;
  left: auto;
}
.emma-message-container .popup-message-name {
  background: #ECA427;
}
.popup-messages-button {
  padding: 8px 53px;
  font-family: 'Mukta Vaani';
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
  border-radius: 100px;
  margin-left: 200px;
}