.popup-messages-avatar {
  position: relative;
  display: flex;
}
.bailey-message-container .popup-messages-avatar {
  order: 1;
}
.right-aligned .popup-messages-avatar {
  order: 2;
}
.popup-messages-avatar img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: #D6BAF0;
}
.popup-messages-avatar.bordered img {
  width: 140px;
  height: 140px;
  border: 6px solid #fff;
}
.popup-messages-avatar.bailey-avatar img {
  background: #a98df6;
}
.popup-messages-avatar.benny-avatar img {
  background: #6fd1d9;
}
.popup-messages-avatar.emma-avatar img {
  background: #F9C151;
}
.avatar-label {
  padding: .5px 33px;
  border-radius: 4px;
  border: 2px solid #2A4169;
  background: #fff;
  font-family: 'Mukta Vaani';
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
}
.popup-messages-avatar.bordered .avatar-label {
  bottom: -16px;
}