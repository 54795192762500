.transactions-income-statement {
  position: relative;
  padding: 40px 20px;
  border-radius: 16px;
  background: #FFF8EB;
  box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
  width: 320px;
  height: 574px;
  transition: transform 1s ease;
  transform: translateX(0%);
}
.transactions-income-statement.should-slide-in {
  transform: translateX(300%);
}
.transactions-income-statement h2 {
  font-family: 'Mukta Vaani';
  font-size: 21px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  padding: 0 30px;
  margin: 0 0 40px;
}
.income-statement-items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.income-statement-items li {
  background: #FCFDFF;
  padding: 12px 12px 12px 36px;
  font-family: 'Mukta Vaani';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.94);
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
}
.income-statement-net-profits {
  display: flex;
  justify-content: space-between;
  margin: 8px 0 0;
  padding: 12px;
  font-family: 'Mukta Vaani';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.94);
}