.intro-game-level-modal-container {
  position: relative;
  z-index: 10;
}
.intro-game-level-modal {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 60px;
  border-radius: 16px;
  box-shadow: 16px 16px 0px 0px #FFB940;
  max-width: 660px;
}
.intro-game-level-modal h2 {
  font-family: 'Inter';
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  color: rgba(0, 27, 59, 0.94);
  margin: 0 0 27px;
}
.intro-game-level-modal-content {
  font-family: 'Mukta Vaani';
  font-size: 21px;
  font-weight: 500;
  line-height: 28px;
}
.intro-game-level-modal-content p {
  margin: 0 0 10px;
}
.intro-game-level-modal-content p:last-child {
  margin-bottom: 0;
}