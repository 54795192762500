.transactions-decisions-wrapper {
  background: #fff;
  box-shadow: 0px 20px 32px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 45px 100px 35px;
  position: relative;
  max-width: 685px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.transactions-decisions-wrapper:before,
.transactions-decisions-wrapper:after {
  display: block;
  content: '';
  width: 137px;
  height: 101px;
  background-size: 100% 100%;
  position: absolute;
}
.transactions-decisions-wrapper:before {
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom right, #F9C151 50%, transparent 50%);
  border-top-left-radius: 16px;
}
.transactions-decisions-wrapper:after {
  bottom: 0;
  right: 0;
  background-image: linear-gradient(to top left, #F9C151 50%, transparent 50%);
  border-bottom-right-radius: 16px;
}

.transactions-decisions-pagination {
  display: flex;
  margin: 0 0 35px;
  padding: 0;
  list-style: none;
  gap: 12px;
  align-self: flex-start;
  margin-left: 100px;
}
.transactions-decisions-pagination li {
  width: 18px;
  height: 18px;
  background: rgba(0, 27, 59, 0.15);
  border-radius: 50%;
}
.transactions-decisions-pagination li.active {
  background: #F9C151;
}

.transaction-description-question-wrapper {
  font-family: 'Mukta Vaani';
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: rgba(0, 27, 59, 0.94);
  width: 100%;
}
.transaction-description {
  margin: 0 0 30px;
  text-align: left;
}
.transaction-question {
  font-weight: 700;
  margin-bottom: 50px;
}

.transactions-answer-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  margin-bottom: 44px;
}
.transactions-answer-options button {
  padding: 15px 35px 15px 35px;
  border-radius: 4px;
  border: 1px solid rgba(0, 27, 59, 0.2);
  background: rgba(0, 27, 43, 0.02);
  white-space: nowrap;
}
.transactions-answer-options button.active {
  position: relative;
}
.transactions-answer-options button.correct {
  background: rgba(234, 243, 236, 1);
  border: 1px solid #2DA548
}
.transactions-answer-options button.incorrect {
  background: #FAEDED;
  border: 1px solid #EC5E59;
}
.transactions-answer-options button.active:after {
  display: block;
  content: '';
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  right: 4px;
}
.transactions-answer-options button.correct:after {
  background: url('./images/icon-correct-option.svg') no-repeat center center;
}
.transactions-answer-options button.incorrect:after {
  background: url('./images/icon-incorrect-option.svg') no-repeat center center;
}

.transactions-buttons {
  display: flex;
  align-items: center;
  column-gap: 16px;
  flex: 0 0 32px;
}
.transactions-buttons button {
  padding: 8px 16px;
  margin: 0;
}
